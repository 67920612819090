<template>
  <v-card>
    <v-dialog
        fullscreen
        v-model="preview"
    >
      <div class="image-wrapper">
        <div class="image-content" v-if="!is_pdf">
          <v-img
              aspect-ratio="1"
              :src="file_url"
              max-height="90vh"
              style="position: relative"
          >
            <v-btn @click="preview = false; is_pdf = false"
                   class="white"
                   depressed
                   style="position: absolute; top: 0; right: 0"
                   :v-ripple="false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-img>
        </div>
      </div>

    </v-dialog>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="py-2">
          <v-col cols="12">
            <v-select :items="document_ownership_types_select" filled label="Вид документу"
                      hide-details
                      v-model="document_type"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="document_type ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="6">
            <date-component v-model="document_date" req label="Дата документа"/>
          </v-col>
          <v-col cols="6">
            <v-text-field hide-details filled label="№ документа"
                          v-model="document_number"
                          color="grey"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea hide-details filled label="Коментар"
                        rows="2"
                        v-model="comment"
                        color="grey"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-text>
      <div class="grey lighten-4">
        <div class="d-flex align-center pa-3">
          <label :for="`my-file-Input-${itemId}`"
                 class="success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small"
                 style="cursor: pointer"
          >
            <v-icon left size="20" dark>mdi-plus</v-icon>
            Новий файл
          </label>
          <v-file-input
              multiple
              hide-input
              hide-details
              :id="`my-file-Input-${itemId}`"
              style="display: none"
              :key="itemId"
              @change="changeFile"
          />
        </div>
        <v-divider></v-divider>
        <div class="d-flex align-center flex-wrap">
          <v-col cols="12" sm="12" md="6" v-for="file in files" :key="`c-file-${itemId}-idx--${file.id}`">
            <v-card>
              <v-list-item>
                <v-list-item-icon class="mr-3" @click="preview_image(file)" style="cursor: pointer">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon size="32" v-if="file" v-on="on" v-bind="attrs">
                        {{ getFileIcon(file.file_ext) }}
                      </v-icon>
                    </template>
                    <span>Попередній перегляд</span>
                  </v-tooltip>

                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                      :style="file.delete ? 'text-decoration: line-through' : ''"
                      class="grey--text"
                      style="cursor: pointer; text-decoration: underline; font-size: .93rem"
                  >
                    <a :href="getFile(file)" :download="`${file.file_name_origin}`" target="_blank"
                       rel="noopener noreferrer" v-if="!file.binary">
                      {{ file.file_name_origin }}
                    </a>
                    <template v-else>
                      {{ file.file_name_origin }}
                    </template>
                  </v-list-item-title>
                  <v-list-item-subtitle
                      :style="file.delete ? 'text-decoration: line-through' : ''"
                      style="font-size: .8rem"
                  >
                    <template v-if="file.binary">
                      Додано щойно
                    </template>
                    <template v-else>
                      Завантажено: {{ file.create_date | formatDate }}
                    </template>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon :class="file.delete ? 'success' : 'error lighten-1'" @click.stop="deleteFile(file)">
                    <v-icon color="white">
                      {{ file.delete ? 'mdi-backup-restore' : 'mdi-delete' }}
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="!files.length">
            <div class="empty-files">
              Вкладені файли відсутні. Натисніть "Новий файл"
            </div>
          </v-col>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="updateService" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {BASE_URL_AXIOS} from '@/utils/axios'

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {document_ownership_types_select, ext_obj} from "@/utils/icons";
import {CREATE_FLAT_DOCUMENTS, REMOVE_FLAT_DOCUMENTS, UPDATE_FLAT_DOCUMENTS} from "@/store/actions/flat_documents";
import {maxBy} from 'lodash'
// import pdf from 'vue-pdf'

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'flat_document_modal_delete'


export default {
  name: "HWP_Modal_FlatDocument",
  mixins: [ModalComponentMixin],
  data() {
    return {
      base_url: BASE_URL_AXIOS,
      files: this.item.files || [],
      flat_id: this.item.flat_id,
      document_type: this.item.document_type || null,
      document_date: this.item.document_date || null,
      document_number: this.item.document_number || '',
      comment: this.item.comment || '',
      document_ownership_types_select,
      preview: false,
      file_url: '',
      is_pdf: false
    }
  },
  methods: {
    changeFile(payload) {
      if (!payload) {
        return
      }

      const max_obj = maxBy(this.files, i => i.id)
      const max = max_obj ? (max_obj.id || 0) : 0

      payload.forEach((item, idx) => {
        const file_ext = (ext_obj.find(item_ext => item_ext.mime === item.type)) || {ext: 'txt', mime: ''}
        const file_obj = {
          id: (max || 0) + idx + 19999999,
          file_path: item.name,
          file_ext: file_ext.ext,
          file_name: item.name,
          file_name_origin: item.name,
          binary: item,
        }
        this.files.push(file_obj)
      })
    },
    getFileIcon(ext) {
      const file_ext = (ext_obj.find(item_ext => item_ext.ext === ext)) || {ext: 'txt', mime: ''}
      return file_ext.icon || 'mdi-document'
    },
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.files = this.item.files || []
      this.flat_id = this.item.flat_id
      this.document_type = this.item.document_type || null
      this.document_date = this.item.document_date || null
      this.document_number = this.item.document_number || ''
      this.comment = this.item.comment || ''

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення документу`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Вид документу має бути заповнений',
          color: 'error lighten-1'
        })
        return undefined
      }


      if (this.isNew) {

        const formData = new FormData()
        formData.append('flat_id', this.flat_id)
        formData.append('document_type', this.document_type)
        formData.append('document_date', this.document_date)
        formData.append('document_number', this.document_number)
        formData.append('comment', this.comment)
        this.files.forEach(item => {
          if (item.binary) {
            formData.append('files', item.binary)
          }
        })

        this.$store.dispatch(CREATE_FLAT_DOCUMENTS, formData)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        const formData = new FormData()
        formData.append('id_', this.itemId)
        formData.append('flat_id', this.flat_id)
        formData.append('document_type', this.document_type)
        formData.append('document_date', this.document_date)
        formData.append('document_number', this.document_number)
        formData.append('comment', this.comment)
        this.files.forEach(item => {
          if (item.binary) {
            formData.append('files', item.binary)
          } else {
            if (item.delete) {
              formData.append('files_delete', item.id)
            }
          }
        })

        this.$store.dispatch(UPDATE_FLAT_DOCUMENTS, formData)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    getServiceName() {
      if (this.service_id) {
        const ind = this.services.find(item => item.value === this.service_id)
        if (ind) {
          return ind.text
        }
      }
      return ''
    },
    regularChange(payload) {
      this.regulator_parameters_type_id = payload.value
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.files = payload.files || []
              this.flat_id = payload.flat_id
              this.document_type = payload.document_type || null
              this.document_date = payload.document_date || null
              this.document_number = payload.document_number || ''
              this.comment = payload.comment || ''
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_FLAT_DOCUMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    deleteFile(payload) {
      if (payload.binary) {
        const obj_idx = this.files.indexOf(payload)
        if (payload) {
          this.files.splice(obj_idx, 1)
        }
      } else {
        payload.delete = !payload.delete
      }
    },
    getFile(payload) {
      if (payload.binary) {
        this.$store.dispatch(ALERT_SHOW, { text: 'Для перегляду спершу слід виконати команду зберегти', color: 'secondary' })
        return '#'
      }

      return `${BASE_URL_AXIOS}/${payload.file_path}`
    },
    preview_image(payload) {
      const this_image = this.imageExt(payload.file_ext)
      if (payload.binary) {
        this.$store.dispatch(ALERT_SHOW, {
          text: 'Для перегляду спершу слід виконати команду зберегти',
          color: 'secondary'
        })
        return;
      }

      if (!this_image) {
        return
      }
      if (payload.file_ext === 'pdf') {
        this.is_pdf = true
      }

      this.file_url = `${BASE_URL_AXIOS}/${payload.file_path}`

      this.preview = true
      //
      // staticAPI.get_file(payload.file_path)
      //     .then(response => {
      //       // const type = response.headers['content-type']
      //       const blob = new Blob([response.data])
      //       this.file_url = window.URL.createObjectURL(blob)
      //     })
    },
    imageExt(ext) {
      const image_types = ext_obj.filter(item => item.mime.indexOf('image/') !== -1)
      const obj = image_types.find(item => item.ext === ext)
      return !!obj
    },
  }
}
</script>

<style scoped lang="scss">
.empty-files {
  height: 90px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 3px dashed #4caf507d;
  font-size: 1rem;
  color: #808080e6;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .image-content {
    height: 90%;
    width: 90%;
    position: relative;
  }
}
</style>